const Leadership = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row g-2">
          <div className="col-12">
            <div className="p-2">
              <div
                className="card text-dark mb-3 p-3"
                style={{ maxWidth: "100%", transition:"1.7s ease" }}
              >
                <div className="d-flex">
                  <div>
                    <img
                      src="	https://jiva-automation.com/wp-content/uploads/2022/12/Venkatesh.jpg"
                      alt=""
                    />
                  </div>
                  <div className="ms-3">
                    <h5 className="card-title">Venkatesh A, Managing Director</h5>
                    <h5p className="card-title">Our Mentor</h5p>
                  </div>
                </div>
                <div className="card-body">
                  <p className="card-text" style={{ lineHeight: "2", marginBottom:"23px", fontSize:"18px", fontWeight:"400" }}>
                    Passionate about Industrial Automation & related
                    Technologies responsible for Business Development, Leading
                    and Delivering growth strategies with close to 27 years of
                    experience in Industrial Automation and Control Systems,
                    Consulting, Project Management and leading, technical
                    support, Automation Software Design and Development.
                    Established proficiency in implementing creative solutions
                    and architecture, testing and training, Implementation of
                    factory-wide controls, Hardware – software integration,
                    Plant-to-enterprise integration, Robotics based projects for
                    new and existing systems for various industries like:
                    Automotive, Chemical industries, Food & Beverage, Metals,
                    Material handling, Power Plants/Utilities, Water treatment,
                    Textiles.
                  </p>
                  <p className="card-text" style={{ lineHeight: "1.7", fontSize:"18px", fontWeight:"400" }}>
                    As JIVA Group Managing Director and majority shareholder,
                    committed to creating lasting corporate partnerships,
                    engaging clients, building talented, dedicated teams who
                    enjoy what they do and thrive on diversity. Aligning with
                    the company’s objectives of Customized Solutions & Services
                    happen and delivering excellence
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leadership;
