import { FaLaptop } from "react-icons/fa";
import { MdOutlineEngineering } from "react-icons/md";
import { FaRegHandshake } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaGripLinesVertical } from "react-icons/fa";

export default function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {/* header */}
      {/* <div className="page-header p-0 pb-5">
        <div className="owl-carousel header-carousel position-relative mb-5">
          <div className="owl-carousel-item position-relative">
            <div className="" style={{ height: isMobile ? "50vh" : "700px" }}>
              <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "start", alignItems: "center", paddingLeft: "50px" }}>
                <h1 className="text-white" data-aos="fade-up"
                  data-aos-duration="1000">
                  SMART CUSTOMIZED SOLUTIONS <br />
                  <span style={{ color: "rgb(246, 139, 65)" }}> FOR SMART FACTORIES</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* carosel start */}

      <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel" >
        <div className="carousel-inner">
          <div className="carousel-item active position-relative h-100">

            {/* <video
              src="/assets/home_banner.mp4"
              autoPlay
              muted
              playsInline
              type="video/mp4"
              width={"100%"}
              height={"100%"}
              style={{ marginTop: "-25px" }}
            ></video> */}

             <img src="/assets/home_banner_1.png"  className="w-100 h-100"  />
             <div className="carosel_content" data-aos="zoom-in-down" data-aos-duration="1800">
              <h3 className="giulio_font ">Adding life to Automation</h3>
             </div>
          </div>
          <div className="carousel-item position-relative h-100">
            {/* <video
              src="/assets/home_banner_2.mp4"
              autoPlay
              muted
              playsInline
              type="video/mp4"
              width={"100%"}
              height={"100%"}
              style={{ marginTop: "-25px" }}
            ></video> */}
             <img src="/assets/home_banner_2.png"  className="w-100 h-100"  />

          </div>
          <div className="carousel-item position-relative h-100">

            <video
              src="/assets/home_banner_3.mp4"
              autoPlay
              muted
              playsInline
              type="video/mp4"
              width={"100%"}
              height={"100%"}
              style={{ marginTop: "-25px" }}
            ></video>
             <div className="carosel_content_right" data-aos="zoom-in-down" data-aos-duration="1800">
              <h1 className="text-white"
               style={{ fontFamily:"Roboto sans-serif",fontStyle:"normal",fontSize:"calc(1.525rem + 2.0vw)"}}
              >Automation Solutions to Gear up<br/> your Productivity in <span className="text-danger">Industry 4.0</span></h1>
             </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>


      {/* carosel ends */}

      {/* <div id="carouselExampleAutoplaying" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{backgroundColor: "#1D2939"}}>
        <div className="carousel-indicators  ">
          <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" className="" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1" aria-label="Slide 2" className="active" aria-current="true"></button>
          <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="2" aria-label="Slide 3" className=""></button>
          <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="3" aria-label="Slide 4" className=""></button>
          <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="4" aria-label="Slide 5" className=""></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item" data-bs-interval="3000">
            <div className="w-100 h-100">
            <video
              src="/assets/home_banner.mp4"
              autoPlay
              muted
              playsInline
              loop
              type="video/mp4"
              width={"100%"}
              height={"100%"}
              style={{ marginTop: "-25px" }}
            ></video>
              <div className="carousel-caption text-white   ">
                <div className="p-1 p-lg-5">
                  <h1 className="display-lg-1 fw-lg-bolder fw-bold  display-3 title">Connect with the Best Telecalling
                    Talent
                    from Anywhere.</h1>
                  <p className="lead desc">Don't let geography limit your telecalling success. Find the best talent from
                    anywhere
                    in the world with gOutbound.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item active" data-bs-interval="3000">
            <div className="w-100 h-100">
            <video
              src="/assets/home_banner.mp4"
              autoPlay
              muted
              playsInline
              loop
              type="video/mp4"
              width={"100%"}
              height={"100%"}
              style={{ marginTop: "-25px" }}
            ></video>
              <div className="carousel-caption text-white  ">
                <div className="p-2 p-lg-5">
                  <h1 className="display-lg-1 fw-lg-bolder fw-bold display-3 title">Find Your Next Big Client with Ease.</h1>
                  <p className="lead desc">Hunting for clients doesn't have to be hard. With gOutbound, you can easily find your
                    next big opportunity and close
                    more deals.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="3000">
            <div className="w-100 h-100">
            <video
              src="/assets/home_banner.mp4"
              autoPlay
              muted
              playsInline
              loop
              type="video/mp4"
              width={"100%"}
              height={"100%"}
              style={{ marginTop: "-25px" }}
            ></video>
              <div className="carousel-caption text-white  ">
                <div className="p-2 p-lg-5">
                  <h1 className="display-lg-1 fw-lg-bolder fw-bold display-3 title ">Grow Your Business with Top Telecalling
                    Freelancers.</h1>
                  <p className="lead desc">Level up your business by partnering with top-notch telecalling freelancers on
                    gOutbound. Get the results you deserve.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="3000">
            <div className="w-100 h-100">
            <video
              src="/assets/home_banner.mp4"
              autoPlay
              muted
              playsInline
              loop
              type="video/mp4"
              width={"100%"}
              height={"100%"}
              style={{ marginTop: "-25px" }}
            ></video>
              <div className="carousel-caption text-white  ">
                <div className="p-2 p-lg-5">
                  <h1 className="display-lg-1 fw-lg-bolder fw-bold display-3 title ">Empower Your Telecalling Career with
                    Flexible Work Options.</h1>
                  <p className="lead desc">Say goodbye to the traditional 9-5 grind and hello to flexible telecalling work
                    options
                    that empower your career growth.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="3000">
            <div className="w-100 h-100">
            <video
              src="/assets/home_banner.mp4"
              autoPlay
              muted
              playsInline
              loop
              type="video/mp4"
              width={"100%"}
              height={"100%"}
              style={{ marginTop: "-25px" }}
            ></video>
              <div className="carousel-caption text-white  ">
                <div className="p-2 p-lg-5">
                  <h1 className="display-lg-1 fw-lg-bolder fw-bold display-3 title ">Connect with Top Telecalling Talent
                    Today.</h1>
                  <p className="lead desc">Looking for the best of the best in the telecalling game? Connect with top talent
                    today
                    on gOutbound and see your
                    success soar.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* content */}
      <div className="d-flex justify-content-center " style={{ marginTop: "35px", textAlign: "center" }}>
        <p className="text-center fs-4 text-dark" data-aos="fade-up"
          data-aos-duration="1000" style={{ width: isMobile ? "90%" : "48%", fontFamily: "Inria Sans , sans-serif",
          fontWeight: "300",fontStyle: "normal",fontSize:"28px" }}>
          <p style={{ color: "orange",lineHeight:"0",fontWeight: "700" }}>Our Mission</p> is to deliver high quality, on-time and reliable automation
          solutions  in order to improve the profitability and quality of our customers
          and enhance the health and safety of workers.</p>
      </div>

      {/* video banner start */}
      <div className="w-100 overflow-hidden px-lg-0 position-relative px-2" style={{ height: "550px", marginTop: isMobile ? "-28px" : "38px" }}>
        <video
          src="/assets/home_video.mp4"
          autoPlay
          muted
          playsInline
          loop
          type="video/mp4"
          // width={"100%"}
          style={{ width: "100%", transform: "scale(2)" }}
          height={"100%"}
        ></video>

        <div className=" position-absolute h-100 w-100 bg-transparent p-3 d-flex justify-content-end" style={{ top: isMobile ? "55px" : "5px" }}>
          <img alt="" style={{ height: "60px", width: "160px" }} src="/assets/Picture1.png" />
        </div>

        <div className="video_popup  position-absolute w-100 start-25" style={{ height: isMobile ? "77%" : "100%",top: "0px", background: "#02020263" }}>
          <div className="" style={{ height:"100%" }}>
            <div className="row">
              <div className="video_img col-md-6 d-flex align-items-center justify-content-center py-3" data-aos="fade-up" data-aos-duration="1000" >
                <img height={"86%"} width={"76%"} src="/assets/robo.png" alt="Office" style={{marginLeft:isMobile?"0px":"-155px"}} />
              </div>

              <div className=" col-md-6 d-flex align-items-center py-3 home_link_container">
                <div className="w-100">
                  <h2 className="text-white">Solutions and Services</h2>
                  <div className="row home_link  gy-2">
                    <div className="col-6 ">
                      <Link className="nav-link" to="/engineering-services"><FaGripLinesVertical size={isMobile ? 18 : 28} color="red" /> Engineering Services</Link>
                    </div>
                    <div className="col-6 ">
                      <Link className="nav-link" to="/system-integration"><FaGripLinesVertical size={isMobile ? 18 : 28} color="red" /> System Integration</Link>
                    </div>
                    <div className="col-6 ">
                      <Link className="nav-link" to="/assembly-automation"><FaGripLinesVertical size={isMobile ? 18 : 28} color="red" /> Turnkey Solutions</Link>
                    </div>
                    <div className="col-6 ">
                      <Link className="nav-link" to="/industrial-cranes"><FaGripLinesVertical size={isMobile ? 18 : 28} color="red" /> Industrial Cranes</Link>
                    </div>
                    <div className="col-6 ">
                      <Link className="nav-link" to="/power-tools"><FaGripLinesVertical size={isMobile ? 18 : 28} color="red" /> Atlas Copco Power Tools</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* video banner start */}


      {/* About Start */}

      <div className="overflow-hidden px-lg-0" style={{ marginTop: isMobile ? "5px" : "35px" }}>
        <div className="about px-4 px-lg-2">
          <h6
            className="fs-2 text-uppercase mb-3 px-lg-2"
            style={{ color: "#f68b41" }}
          >
            Established in 2006
          </h6>
          <div className="row g-3 mx-lg-0">
            <div className="col-lg-6 ps-lg-2 Left" style={{ height: "400px" }}>
              <div className="position-relative h-100 d-flex justify-content-between">
                <img
                  data-aos="fade-up-right"
                  className=" img-fluid h-100"
                  src="https://www.olsarchitects.ie/assets/images/projects/Open-Plan-Office-OLeary-Sludds-Architects.jpg"
                  style={{ objectFit: "cover", width: "49%" }}
                  alt=""
                />
                <img
                  data-aos="fade-up-left"
                  className="img-fluid  h-100"
                  src="https://th.bing.com/th/id/OIP.yeUeOUigWFqLLvh2MXNZzAHaE8?rs=1&pid=ImgDetMain"
                  style={{ objectFit: "cover", width: "49%" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 about-text" >
              {/* <h6
                className="fs-2 text-uppercase mb-3"
                style={{ color: "#f68b41" }}
              >
                Established in 2001
              </h6> */}
              <p
                className="mb-5 px-2"
                style={{
                  lineHeight: "1.7",
                  fontSize: "18px",
                  textAlign: "justify",
                  color:"black"
                }}
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                Starting in 2006, our tagline &nbsp;
                <span  className="giulio_font" style={{ fontWeight: "600", color: "black",fontSize:"25px" }}>
                  “Adding life to Automation”{" "}
                </span>
                defines our beliefs in creating Smart Customized Solutions for
                Smart Factories. Founders of Jiva Innovative Automations Pvt
                Ltd., have been associated with reputed companies like M/s.
                Siemens, M/s. Kirloskar with over 80-man years of combined
                experience in similar industries exhibited a pioneering spirit
                and passion for Quality, Total Commitment to Customers on
                Products and Solutions, a highly skilled and motivated Team,
                this underpins everything that we do and defines the company’s
                goals to date.
              </p>
              <Link
                to="/contact"
                style={{ backgroundColor: "#f68b41" }}
                className="btn py-3 px-5"
              >
                Enquiry
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* About end */}

      {/* Feature starts */}

      <div className="px-2 overflow-hidden py-4  px-lg-0">
        <div className=" feature px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div className="col-lg-6 feature" data-aos="zoom-out-right">
              <h6
                className="fs-2 text-uppercase mb-3"
                style={{ color: "#f68b41" }}
              >
                Our Features
              </h6>
              <h1 className="mb-3">
                We Are Trusted Automation Solutions Company
              </h1>
              <div>
                <div>
                  <div className="d-flex">
                    <MdOutlineEngineering
                      className="icon fs-3 me-2"
                      style={{ color: "orange" }}
                    />
                    <div>
                      <h5>Engineering Services</h5>
                    </div>
                  </div>
                  <div style={{ marginLeft: "35px" }}>
                    <p style={{ textAlign: "justify" }}>
                      Group Company, an Authorised Service Partner to Siemens
                      Automation Division.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <img

                      className="icon"
                      src="/assets/icons/business-cycle.png"
                      alt=""
                      style={{ height: "20px", marginRight: "10px" }}
                    />
                    <h5>System Integration</h5>
                  </div>
                  <div style={{ marginLeft: "35px" }}>
                    <p style={{ textAlign: "justify" }}>
                      Preferred Controls & Instrumentaion partner's to QUEST
                      Engineering 2009
                    </p>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <FaLaptop
                      className="icon fs-3 me-2"
                      style={{ color: "orange" }}
                    />
                    <h5>Turnkey Solutions</h5>
                  </div>
                  <div style={{ marginLeft: "35px" }}>
                    <p>Assembly Automation 2012</p>
                    <p>Material Handling Automation 2012</p>
                    <p>Robotic Automation 2012</p>
                    <p>Testing Automation 2016</p>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    {" "}
                    <FaRegHandshake
                      className="icon fs-3 me-2"
                      style={{ color: "orange" }}
                    />
                    <h5>Product Sales and Solutions</h5>
                  </div>
                  <div style={{ marginLeft: "35px" }}>
                    <p>EROGOLIFT 2012</p>
                    <p>Integration partner to FANUC Robot Division 2012</p>
                    <p>ERIKILLA 2013</p>
                    <p>KONECRANES 2015</p>
                    <p>SCHMERSAL 2016</p>
                    <p>DONGSUNG Cranes for Light Profile Rails 2017</p>
                    <p>Preferred Partner to OMRON Cobots since 2018</p>
                    <p>ATLAS COPCO Master Channel Partner, South India 2021</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 pe-lg-0  Right"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  data-aos="zoom-in"
                  className="position-absolute img-fluid w-100 h-100"
                  src="/assets/Narayana/Crane2.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature ends */}


      {/* Quotes starts */}
      {false && <div className="px-2 ">
        <div className="px-2">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5">
              <h6 className=" text-uppercase mb-3" style={{ color: "#f68b41" }}>
                Get A Quote
              </h6>
              <h1 className="mb-5">Request A Free Quote!</h1>
              <p className="mb-5">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit,
                sed stet lorem sit clita duo justo erat amet
              </p>
              <div className="d-flex align-items-center">
                <i className="fa fa-headphones fa-2x flex-shrink-0 bg-primary p-3 text-white"></i>
                <div className="ps-4">
                  <h6>Call for any query!</h6>
                  <h3 className="text-primary m-0">+91 8028605280</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="bg-light text-center p-3 mt-3 ">
                <form>
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        className="form-control border-0"
                        placeholder="Your Name"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="email"
                        className="form-control border-0"
                        placeholder="Your Email"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control border-0 "
                        placeholder="Your Mobile"
                        style={{ height: "55px" }}
                      />
                    </div>

                    <div className="col-12">
                      <textarea
                        className="form-control border-0"
                        placeholder="Special Note"
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn  w-100 py-3"
                        type="submit"
                        style={{ backgroundColor: "#f68b41" }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>}

      {/* Quotes ends */}

      {/*Team  start */}
      <div className="px-2 our_team" style={{ height: "90vh", width: "100%" }}>
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <div>
            <div className="d-flex justify-content-center align-items-center m-4 ">
              <button className="btn btn-lg text-white p-3 px-5">Join Our Team</button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p className=" p-2 ">Lorem ipsum dolor sit, amet consectetur adipisicing elitLorem ipsum dolor sit, amet consectetur adipisicing elit. Mollitia quaerat</p>
            </div>
          </div>
        </div>
      </div>
      {/*Team  ends */}

    </>
  );
}
