import React,{useState,useEffect} from "react";

const Location = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
    {/* style={{ height:isMobile ?"40vh": "60vh",backgroundImage:"url('/assets/banners/location_banner.jpeg')" }} className="page-header" */}
    <div style={{ height:isMobile ?"40vh": "60vh"}} className="position-relative">
        {isMobile ? (
          <img
            // src="/assets/banners/about_mob.png"
            alt="Mobile Image"
            className="img-fluid"
          />
        ) : (
          <img
            src="/assets/banners/location_banner.jpeg"
            alt="Laptop Image"
            className=" w-100 h-100 img-fluid"
          />
        )}
        <div data-aos="zoom-in-down" data-aos-duration="1000"
         className=" h-100 w-100 d-flex justify-content-center align-items-center px-5 position-absolute top-0 ">
            <h1 className="text-white" style={{fontSize:"75px"}}>
              Locations
             {/* <span style={{color:"rgb(246, 139, 65)"}}>JIVA</span> */}
            </h1>
        </div>
      </div>


      <div className="">
        <div className="row g-4 m-4">
          <div className="col-md-6">
            <div className="p-3 border">
              <div>
                <h2>Head Office</h2>
                <p className="fw-bold">Jiva Innovative Automations Pvt.Ltd.</p>
                <p> #360, Girya’s Enclave, 43rd Main Road,</p>
                <p>Ideal Homes Township, Rajarajeshwari Nagar,</p>
                <p>Bengaluru-560 098, Karnataka, India.</p>
              </div>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1131.4365871987047!2d77.7381404961066!3d13.084059253726581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDA1JzAxLjMiTiA3N8KwNDQnMjAuMCJF!5e0!3m2!1sen!2sin!4v1703826716655!5m2!1sen!2sin"
                  title="Google Maps Location"
                  width="100%"
                  height="300"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-3 border">
              <div>
                <h2>Branch Office</h2>
                <p className="fw-bold">Jiva Innovative Automations Pvt.Ltd.</p>
                <p>Buliding No – 07, Near Krishna Green Land Park,</p>
                <p>Kasarvadavali Naka G.B.Road,</p>
                <p>Thane-W-400615 Mumbai, Maharastra, India.</p>
              </div>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1131.4365871987047!2d77.7381404961066!3d13.084059253726581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDA1JzAxLjMiTiA3N8KwNDQnMjAuMCJF!5e0!3m2!1sen!2sin!4v1703826716655!5m2!1sen!2sin"
                  title="Google Maps Location"
                  width="100%"
                  height="300"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-4 m-4">
          <div className="col-md-6">
            <div className="p-3 border">
              <div>
                <h2>Work Office</h2>
                <p className="fw-bold">Jiva Innovative Automations Pvt.Ltd.</p>
                <p> #360, Girya’s Enclave, 43rd Main Road,</p>
                <p>Ideal Homes Township, Rajarajeshwari Nagar,</p>
                <p>Bengaluru-560 098, Karnataka, India.</p>
              </div>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1131.4365871987047!2d77.7381404961066!3d13.084059253726581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDA1JzAxLjMiTiA3N8KwNDQnMjAuMCJF!5e0!3m2!1sen!2sin!4v1703826716655!5m2!1sen!2sin"
                  title="Google Maps Location"
                  width="100%"
                  height="300"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-3 border">
              <div>
                <h2>Factory</h2>
                <p className="fw-bold">Jiva Innovative Automations Pvt.Ltd.</p>
                <p> #6&7, 1st Cross, Sri Veeranjaneya Swamy Temple Road,</p>
                <p> Muneshwara Badavane, Near Govt. Hospital,</p>
                <p>Viswaneedam Post, Hegganahalli, Bangalore - 560091.</p>
              </div>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1131.4365871987047!2d77.7381404961066!3d13.084059253726581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDA1JzAxLjMiTiA3N8KwNDQnMjAuMCJF!5e0!3m2!1sen!2sin!4v1703826716655!5m2!1sen!2sin"
                  title="Google Maps Location"
                  width="100%"
                  height="300"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
