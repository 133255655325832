// import Layout from "../../../Components/Layout";

export default function RoboticAutomation() {
  return (
    <>
      <div style={{ height: "100%" }}>
        <img
          className="img-fluid"
          src="/assets/System Integration/robotic_banner.png"
          alt=""
        />
      </div>

      <div className="overflow-hidden px-lg-0 " style={{ marginTop: "50px" }}>
        <div className="about px-2  px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-6 ps-lg-0"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="assets/Photos/Cat manipulator (4).jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 about-text">
              <h6
                className="fs-2 text-uppercase mb-3"
                style={{ color: "#f68b41" }}
              >
                Robotic Automation
              </h6>

              <p
                style={{
                  lineHeight: "1.7",
                  textAlign: "justify",
                  fontSize: "17px",
                }}
              >
                Our robotic solutions deployed for critical manufacturing or
                other industrial process, including Assembly, Welding, Machine
                Tending, Packing, Labelling, Painting, Inspection, Spraying,
                Testing, and more, providing high endurance, precision and speed
                for the tasks.
              </p>
            </div>
          </div>
        </div>
        <div className="py-3 " style={{ marginTop: "40px" }}>
          <div className="row row-cols-1 row-cols-lg-4  ">
            <div className="col">
              <div
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img1.jpg"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
            <div className="col">
              <div
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img2.jpg"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
            <div className="col">
              <div
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img3.jpeg"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
            <div className="col">
              <div
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img4.JPG"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
