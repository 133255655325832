import { TiTick } from "react-icons/ti";
import { Link } from "react-router-dom";

const SystemIntegration = () => {
  return (
    <>
      {/* Header */}
      <div style={{ height: "100%" }}  className="position-relative">
        <img
          className="img-fluid"
          src="/assets/banners/System_Integration.png"
          alt=""
        />
          {/* <div data-aos="zoom-in-down" data-aos-duration="1000"
         className=" h-100 w-100 d-flex justify-content-center align-items-center px-5 position-absolute top-0 ">
            <h1 className="text-white" style={{fontSize:"75px"}}>
            System Integration
            
            </h1>
        </div> */}
      </div>

      {/* Engineering services */}
      <div className="overflow-hidden  px-lg-0" style={{ marginTop: "50px" }}>
        <div className=" about px-2  px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-6 ps-lg-0 wow fadeInLeft"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="assets/Photos/DSC02025.JPG"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <h6 className="text-primary text-uppercase mb-3">
                System Integration
              </h6>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">
                  Industrial Automation with capability on both Factory &
                  Process Automation.
                </p>
              </div>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">
                  CNC, PLC, SCADA, Servo's, Drives, HMI's based upgradation &
                  commissioning support.
                </p>
              </div>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">
                  Control & Electrical Panels CE, UL Certified.
                </p>
              </div>

              <Link to="" className="btn btn-primary py-3 px-5">
                Explore More
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3" style={{ marginTop: "40px" }}>
        <div className="row row-cols-1 row-cols-lg-4  ">
          <div className="col mt-3">
            <div
                           
              className="px-2 border "
              style={{ height: "400px", width: "100%", objectFit: "cover" }}
            >
              <img
                src="/assets/System Integration/img1.jpg"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
          <div className="col mt-3">
            <div
                           
              className="px-2 border "
              style={{ height: "400px", width: "100%", objectFit: "cover" }}
            >
              <img
                src="/assets/System Integration/img2.jpg"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
          <div className="col mt-3">
            <div
                           
              className="px-2 border "
              style={{ height: "400px", width: "100%", objectFit: "cover" }}
            >
              <img
                src="/assets/System Integration/img3.jpeg"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
          <div className="col mt-3">
            <div
                           
              className="px-2 border "
              style={{ height: "400px", width: "100%", objectFit: "cover" }}
            >
              <img
                src="/assets/System Integration/img4.JPG"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SystemIntegration;
