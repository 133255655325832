// import Layout from "../../../Components/Layout";

export default function AssemblyAutomation() {
  return (
    <>
      <div style={{ height: "100%" }}  className="position-relative">
        <img
          className="img-fluid"
          src="/assets/System Integration/assembly_banner.png"
          alt=""
        />
          <div data-aos="zoom-in-down" data-aos-duration="1000"
         className=" h-100 w-100 d-flex justify-content-center align-items-center px-5 position-absolute top-0 ">
            <h1 className="text-white" style={{fontSize:"75px"}}>
             {/* <span style={{color:"rgb(246, 139, 65)"}}>Assembly Automation</span> */}
            </h1>
        </div>
      </div>

      <div className="overflow-hidden px-lg-0 " style={{ marginTop: "50px" }}>
        <div className="about px-2  px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
            
              className="col-lg-6 ps-lg-0"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="assets/Photos/Cat manipulator (4).jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text"
            
            >
              <h6
                className="fs-2 text-uppercase mb-3"
                style={{ color: "#f68b41" }}
              >
                Assembly Automation
              </h6>

              <p
                style={{
                  lineHeight: "1.7",
                  textAlign: "justify",
                  fontSize: "17px",
                }}
              >
                Assembly line solutions provided by us have streamlined
                production line and improved efficiencies, helps manufacturers
                to meet increasing demand for cost-effective and high-quality
                products. These lines deploy robotics, conveyors, vision or
                other automated technology to complete production tasks in
                multiple stations, moving the part or product through each step
                in an automated production sequence.
              </p>
              <p style={{ lineHeight: "1.7", fontSize: "17px" }}>
                Assembly line solutions provided by us have streamlined
                production line and improved efficiencies, helps manufacturers
                to meet increasing demand for cost-effective and high-quality
                products. These lines deploy robotics, conveyors, vision or
                other automated technology to complete production tasks in
                multiple stations, moving the part or product through each step
                in an automated production sequence.
              </p>

              <p className="fw-1 fs-4 text-center text-danger">
                “ We have provided Customised Automated & Semi Automated
                solutions based on production needs.
              </p>
            </div>
          </div>
        </div>
        <div className="py-3 " style={{ marginTop: "40px" }}>
          <div className="row row-cols-1 row-cols-lg-4  ">
            <div className="col">
              <div
                                
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img1.jpg"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
            <div className="col">
              <div
                                
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img2.jpg"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
            <div className="col">
              <div
                                
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img3.jpeg"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
            <div className="col">
              <div
                                
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img4.JPG"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
