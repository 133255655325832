import React from "react";
import { TiTick } from "react-icons/ti";

function IndustrialCranes() {
  return (
    <>
      {/* Header */}
      <div style={{ height: "100%" }}  className="position-relative">
        <img
          className="img-fluid"
          src="/assets/System Integration/industrial_banner.png"
          alt=""
        />
          <div data-aos="zoom-in-down" data-aos-duration="1000"
         className=" h-100 w-100 d-flex justify-content-center align-items-center px-5 position-absolute top-0 ">
            {/* <h1 className="text-white" style={{fontSize:"75px"}}>
            Industrial Cranes
              <span style={{color:"rgb(246, 139, 65)"}}>JIVA</span> 
            </h1> */}
        </div>
      </div>
      {/* Header End */}

      {/* Industrial Cranes */}
      <div className="overflow-hidden  px-lg-0" style={{ marginTop: "50px" }}>
        <div className=" about px-2  px-lg-0">
          <div
            className="row g-5"
          
          >
            <div className="col-lg-6">
              <h6
                className="fs-2 text-uppercase mb-3"
                style={{ color: "#f68b41" }}
              >
                Industrial Cranes
              </h6>

              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">
                  Solutions with alumunium, Steel Light Profile Cranes for
                  Handling upto 2 Ton.
                </p>
              </div>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">
                  Customized End of Arm Tooling, Lifting Tackles for all
                  Industry requirements.
                </p>
              </div>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">
                  5000m of LCS, 100+ Overhead EOT Cranes, Granty Cranes, 1000m
                  of Conveyors in use.
                </p>
              </div>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">
                  Exclusive Material Handling Services & Application Experts
                  across India.
                </p>
              </div>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">
                  We are preferred partners to KITO Cranes.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-4 align-items-center">
                <div
                  className="col-sm-6 "
                  style={{ height: "300px", width: "100% " }}
                >
                  <img
                    height={"100%"}
                    width={"100%"}
                    src="/assets/cranes/IMG-20210330-WA0002.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3" style={{ marginTop: "40px" }}>
        <div className="row row-cols-1 row-cols-lg-4  ">
          <div className="col mt-3">
            <div
              className="px-2 border "
              style={{ height: "400px", width: "100%", objectFit: "cover" }}
            >
              <img
                src="/assets/cranes/img1.jpg"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
          <div className="col mt-3">
            <div
            
              className="px-2 border "
              style={{ height: "400px", width: "100%", objectFit: "cover" }}
            >
              <img
                src="/assets/cranes/img2.jpg"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
          <div className="col mt-3">
            <div
            
              className="px-2 border "
              style={{ height: "400px", width: "100%", objectFit: "cover" }}
            >
              <img
                src="/assets/cranes/img3.jpeg"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
          <div className="col mt-3">
            <div
            
              className="px-2 border "
              style={{ height: "400px", width: "100%", objectFit: "cover" }}
            >
              <img
                src="/assets/cranes/img4.jpg"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Industrial Cranes */}
    </>
  );
}

export default IndustrialCranes;
