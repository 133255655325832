import { IoLocationOutline } from "react-icons/io5";
import { LuPhoneForwarded } from "react-icons/lu";
import { useEffect } from "react";

export default function Contactus() {

  useEffect(() => {
    window.scrollTo(0, 0)
    }, [])
  return (
    <>
     <div style={{ height: "100%" }}  className="position-relative">
        <img
          className="img-fluid"
          src="/assets/engineer_service.png"
          alt=""
        />
          <div data-aos="zoom-in-down" data-aos-duration="1000"
         className=" h-100 w-100 d-flex justify-content-center align-items-center px-5 position-absolute top-0 ">
            <h1 className="text-white" style={{fontSize:"75px"}}>
             Contact Us
             {/* <span style={{color:"rgb(246, 139, 65)"}}>JIVA</span> */}
            </h1>
        </div>
      </div>

      <div className=" overflow-hidden px-2 px-lg-0">
        <div className="contact-page py-5 px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-md-6 contact-form wow fadeIn"
              data-wow-delay="0.1s"
            >
              {/* <h6 className="text-dark text-uppercase">Get In Touch</h6> */}
              <h1 className="mb-4">We're Ready, Let's Talk.</h1>
             
              <div className="bg-light p-4">
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Your Email"
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder="Subject"
                        />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          style={{ height: "100px" }}
                        ></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div
              className="col-md-6 pe-lg-0 col-sm-3 wow fadeInRight"
              data-wow-delay="0.1s"
            >
              <div className="position-relative h-100">
                <iframe
                  className="position-absolute w-100 h-100"
                  style={{ objectFit: "cover" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                  frameBorder="0"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Details */}
      <div className="border">
        <div className="card-title d-flex justify-content-center fs-3 fw-bold">
          <p>Jiva Innovative Automations Pvt. Ltd.</p>
        </div>
        <div className="d-flex justify-content-center">
          <div className="row row-cols-2 row-cols-lg-3 g-2 g-lg-3">
            <div className="col">
              <div className="p-3 ">
                <div className="card-body d-flex align-items-center">
                  <IoLocationOutline
                    style={{ fontSize: "30px", color: "orange" }}
                  />
                  <div className="ms-3">
                    <p>#6&7, 1st Cross, Sri Veeranjaneya Swamy Temple Road,</p>
                    <p>Muneshwara Badavane, Near Govt. Hospital,</p>
                    <p>Viswaneedam Post, Hegganahalli,</p>
                    <p>Bangalore - 560091</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="p-3 " style={{ height: "381.6px" }}>
                <div className="card-body d-flex align-items-center">
                  <IoLocationOutline
                    style={{ fontSize: "30px", color: "orange" }}
                  />
                  <div className="ms-3">
                    <p>No. 360, 43rd Main Road</p>
                    <p>Ideal Homes Township,</p>
                    <p>Rajarajeshwarinagar, Bangalore - 560098</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="p-3 " style={{ height: "381.6px" }}>
                <div className="text-dark mb-3">
                  <div className="card-body d-flex align-items-center">
                    <LuPhoneForwarded
                      style={{ fontSize: "30px", color: "orange" }}
                    />
                    <div className="ms-3">
                      <p>+91 80 2860 5322 | +91 80 28605258 </p>
                      <p>+91 80 28605280 | +91 99729 63729</p>
                      <p>info@jivatech.com</p>
                      <p>support@jivatech.com</p>
                      <p>www.jiva-automation.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
