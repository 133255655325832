import { useEffect } from "react";

import Home from "./pages/Home";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Company from "./pages/About/Company";
import WhyJiva from "./pages/About/WhyJiva";
import Location from "./pages/About/Location";
import Career from "./pages/Contact/Career";
import Contactus from "./pages/Contact/Contactus";
import Brochure from "./pages/Media/Brochure";
import Videos from "./pages/Media/Videos";
import CaseStudies from "./pages/Media/CaseStudies";
import EngineeringServices from "./pages/Services/EngineeringServices";
import SystemIntegration from "./pages/Services/SystemIntegration";
import Photos from "./pages/Media/Photos";
import Industary from "./pages/Industary";
import IndustrialCranes from "./pages/Services/IndustrialCranes";
import AtlasCopco from "./pages/Services/AtlasCopco";
import AssemblyAutomation from "./pages/Services/TurnkeySolutions/AssemblyAutomation";
import MaterialHandlingAutomation from "./pages/Services/TurnkeySolutions/MaterialHandlingAutomation";
import RoboticAutomation from "./pages/Services/TurnkeySolutions/RoboticAutomation";
import SpecialPurposeMachines from "./pages/Services/TurnkeySolutions/SpecialPurposeMachines";
import TestingAutomation from "./pages/Services/TurnkeySolutions/TestingAutomation";
import AOS from "aos";
import "aos/dist/aos.css";
import CallToAction from "./Components/CallToAction";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          {/* About */}

          <Route path="/company" element={<Company />} />
          <Route path="/whyJiva" element={<WhyJiva />} />
          <Route path="/location" element={<Location />} />

          {/* Services */}
          <Route path="/assembly-automation" element={<AssemblyAutomation />} />
          <Route
            path="/engineering-services"
            element={<EngineeringServices />}
          />
          <Route path="/system-integration" element={<SystemIntegration />} />
          <Route path="/industrial-cranes" element={<IndustrialCranes />} />
          <Route path="/power-tools" element={<AtlasCopco />} />

          {/* Industary */}
          <Route path="/industary" element={<Industary />} />

          {/* Media */}
          <Route path="/photos" element={<Photos />} />
          <Route path="/brochure" element={<Brochure />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/caseStudies" element={<CaseStudies />} />

          {/* Contact */}
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contactus />} />

          <Route
            path="/material-handling-automation"
            element={<MaterialHandlingAutomation />}
          />
          <Route path="/robotic-automation" element={<RoboticAutomation />} />
          <Route
            path="/special-purpose-machines"
            element={<SpecialPurposeMachines />}
          />
          <Route path="/testing-automation" element={<TestingAutomation />} />
        </Routes>
        <CallToAction />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
