import { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { MdOutlineEngineering,MdOutlineMiscellaneousServices } from "react-icons/md";
import { FaLaptop } from "react-icons/fa";
import { GiCargoCrane } from "react-icons/gi";
import { GiDrill } from "react-icons/gi";
import { useState } from "react";
import { HiOutlineHome } from "react-icons/hi";
import { SiGoogleearthengine } from "react-icons/si";
import { GiCrane } from "react-icons/gi";
import { SlHome } from "react-icons/sl";
import "./header.css"

function Header() {
  const [activeItem, setActiveItem] = useState(null);


  const pathName = useLocation().pathname;
  console.log(pathName );
  const handleNavItemClick = (itemName) => {
    setActiveItem(itemName);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-white navbar-light shadow border-primary p-0  "
        style={{ position: "sticky", top: "0px", zIndex: "99" }}
      >
        <Link
          to="/"
          className="navbar-brand  d-flex align-items-center px-4 px-lg-5 "
          style={{ paddingTop: "15px" }}
        >
          <img
            src="/assets/Picture1.png"
            style={{ width: "140px" }}
            alt=""
          />
        </Link>

        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarCollapse"
        >
          <ul className="navbar-nav mr-auto">
            <li className={`nav-item ${pathName === "/" ? "active" : ""}`}>
              <Link
                to="/"
                className="nav-link"
                style={{ color: pathName === "/" ? "orange" : "inherit" }}

                // onClick={() => handleNavItemClick("Home")}
                // onMouseOver={() => handleNavItemClick("Home")}
              >
                {!isMobile ? <SlHome style={{ height: "25px", width: "25px", marginTop: "-6px" }} /> : "Home"}
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className={`nav-link dropdown-toggle ${pathName === "/company"||pathName === "/whyJiva"||pathName === "/location" ? "active" : ""
                  }`}
                id="navbarDropdownAbout"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                activeClassName="active"
                // onClick={() => handleNavItemClick("About")}
                // onMouseOver={() => handleNavItemClick("About")}
                style={{ color: pathName === "/company"||pathName === "/whyJiva"||pathName === "/location" ? "orange" : "inherit" }}
              >
                About us
              </Link>

              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownAbout"
              >
                <Link to="/company" className="dropdown-item">
                  Company
                </Link>
                <Link to="/whyJiva" className="dropdown-item">
                  Why Jiva
                </Link>
                <Link className="dropdown-item">Leadership</Link>
                <Link to="/location" className="dropdown-item">
                  Location
                </Link>
                <Link to="/company" className="dropdown-item">Our Partners</Link>
              </div>
            </li>

            <li className="nav-item dropdown">
              <Link
                className={`nav-link dropdown-toggle 
                ${pathName === "/engineering-services"
                ||pathName === "/system-integration"
                ||pathName === "/special-purpose-machines"
                ||pathName === "/assembly-automation"
                ||pathName === "/material-handling-automation"
                ||pathName === "/robotic-automation"
                ||pathName === "/testing-automation"
                 ? "active" : ""
                  }`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                activeClassName="active"
                // onClick={() => handleNavItemClick("Solutions & Services")}
                // onMouseOver={() => handleNavItemClick("Solutions & Services")}
                style={{
                  color:
                  pathName === "/engineering-services"
                  ||pathName === "/system-integration"
                  ||pathName === "/special-purpose-machines"
                  ||pathName === "/assembly-automation"
                  ||pathName === "/material-handling-automation"
                  ||pathName === "/robotic-automation"
                  ||pathName === "/testing-automation"
                      ? "orange"
                      : "inherit",
                }}
              >
                Solutions & Services
              </Link>
              <div
                className="dropdown-menu"
                style={{ width: "240px" }}
                aria-labelledby="navbarDropdownSolutions"
              >
                <Link to="/engineering-services" className="dropdown-item">
                  <MdOutlineEngineering
                    className="icon"
                    style={{ color: "orange" }}
                  />{" "}
                  Engineering Services
                </Link>
                <Link to="/system-integration" className="dropdown-item">
                  <SiGoogleearthengine className="icon" style={{ color: "orange" }} />
                  System Integration
                </Link>

                <li>
                  <Link
                    // to="/assembly-automation"
                    className="dropdown-item sub_menu "
                  >
                    <FaLaptop className="icon" color="orange" />Turnkey Solutions<span style={{ float: "right", paddingRight: "8px" }}>&raquo;</span>
                  </Link>

                  <ul className="dropdown-innermenu dropdown-submenu" >
                    <li>
                      <Link className="dropdown-item" to="/assembly-automation">Assembly Automation</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/material-handling-automation"> Material Handling Automation</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/robotic-automation"> Robotic Automation</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/testing-automation">Testing Automation</Link>
                    </li>
                  </ul>

                </li>


                <Link to="/special-purpose-machines" className="dropdown-item">
                  <GiDrill className="icon" style={{ color: "orange" }} />
                  Special Purpose Machine
                </Link>
              </div>
            </li>

            <li className="nav-item dropdown">
              <Link
                className={`nav-link dropdown-toggle ${pathName ==="/power-tools"||pathName ==="/industrial-cranes" ? "active" : ""
                  }`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                activeClassName="active"
                // onClick={() => handleNavItemClick("sales")}
                // onMouseOver={() => handleNavItemClick("sales")}
                style={{
                  color:
                    pathName ==="/power-tools"||pathName ==="/industrial-cranes" ? "orange" : "inherit",
                }}
              >
                Sales
              </Link>
              <div
                className="dropdown-menu"
                style={{ width: "270px" }}
                aria-labelledby="navbarDropdownMedia"
              >
                <Link to="/power-tools" className="dropdown-item">
                  <GiDrill className="icon" style={{ color: "orange" }} />
                  Atlas Copco Power Tools
                </Link>
                <Link
                    to="/industrial-cranes" 
                    className="dropdown-item">
                      <GiCrane className="icon" style={{ color: "orange" }} />{" "}
                    {" "}
                    Industrial Cranes & Solutions
                  </Link>
                <Link to="#" className="dropdown-item">
                <GiCargoCrane className="icon" style={{ color: "orange" }} /> {" "}Industrial Mainpulator
                </Link>
                <Link to="#" className="dropdown-item">
                 <MdOutlineMiscellaneousServices className="icon" style={{ color: "orange" }}  /> {" "}Indutrials Spares
                </Link>
              </div>
            </li>




            <li
              className={`nav-item ${pathName === "/industary" ? "active" : ""
                }`}
            >
              <Link
                to="/industary"
                className="nav-link"
                style={{
                  color: pathName === "/industary" ? "orange" : "inherit",
                }}
                // onClick={() => handleNavItemClick("Footprints")}
                // onMouseOver={() => handleNavItemClick("Footprints")}
              >
                Footprints
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                className={`nav-link dropdown-toggle ${
                  pathName === "/photos"||pathName ==="/videos"||pathName ==="/case-studies"||pathName ==="/brochures" ? "active" : ""
                  }`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                activeClassName="active"
                // onClick={() => handleNavItemClick("Media & Downloads")}
                // onMouseOver={() => handleNavItemClick("Media & Downloads")}
                style={{
                  color:
                  pathName === "/photos"||pathName ==="/videos"||pathName ==="/case-studies"||pathName ==="/brochures" ? "orange" : "inherit",
                }}
              >
                Media & Downloads
              </Link>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMedia"
              >
                <Link to="/photos" className="dropdown-item">
                  Photos
                </Link>
                <Link to="/videos" className="dropdown-item">
                  Videos
                </Link>
                <Link to="/case-studies" className="dropdown-item">
                  Case Studies
                </Link>
                <Link to="/brochures" className="dropdown-item">
                  Brochures
                </Link>
              </div>
            </li>

            <li className="nav-item dropdown">
              <Link
                className={`nav-link dropdown-toggle ${pathName === "/career"||pathName ==="/contact" ? "active" : ""
                  }`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                activeClassName="active"
                // onClick={() => handleNavItemClick("Contact")}
                // onMouseOver={() => handleNavItemClick("Contact")}
                style={{
                  color: pathName === "/career"||pathName ==="/contact" ? "orange" : "inherit",
                }}
              >
                Contact
              </Link>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownContact"
              >
                <Link to="/career" className="dropdown-item">
                  Career
                </Link>
                <Link to="/contact" className="dropdown-item">
                  Contact Us
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Header;