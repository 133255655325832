import { FaShuttleSpace } from "react-icons/fa6";

function Industary() {
  const data = [
    {
      title: "Aerospace Manufacturing",
      icon: "/assets/icons/aerospace.png",
      animate: "zoom-in-up",
    },
    {
      title: "Defence Manufacturing",
      icon: "/assets/icons/shield.png",
      animate: "flip-left",
    },
    {
      title: "Automotive",
      icon: "/assets/icons/automation.png",
    },
    {
      title: "Fabrication",
      icon: "/assets/icons/textile.png",
      animate: "flip-left",
    },
    {
      title: "Enegry",
      icon: "/assets/icons/save-energy.png",
      animate: "flip-right",
    },
    {
      title: "Heavy Engineering Machinery",
      icon: "/assets/icons/machinery.png",
      animate: "fade-left",
    },
    {
      title: "Chemicals",
      icon: "/assets/icons/laboratory.png",
      animate: "fade-left",
    },
    {
      title: "Textiles",
      icon: "/assets/icons/spinning-wheel.png",
      animate: "fade-right",
    },
    {
      title: "Water Treatment",
      icon: "/assets/icons/water.png",
      animate: "fade-left",
    },
    {
      title: "Steel",
      icon: "/assets/icons/welder.png",
      animate: "fade-left",
    },
    {
      title: "F & B",
      icon: "/assets/icons/welder.png",
      animate: "fade-up-right",
    },
    {
      title: "Pharma",
      icon: "/assets/icons/pharmacy.png",
      animate: "fade-up-left",
    },
    {
      title: "Capital Goods & White Goods",
      icon: "/assets/icons/supply.png",
      animate: "flip-down",
    },
    {
      title: "Consumer Electronics",
      icon: "/assets/icons/supply.png",
      animate: "zoom-in",
    },
  ];
  return (
    <>
      <div className="border" style={{ marginBottom: "4rem", width: "" }}>
        <div className="d-flex justify-content-center p-2" style={{ height: "350px", width: "" }}>
          <img
            src="https://www.allegromicro.com/-/media/images/misc/map.png?h=650&iar=0&w=1308&hash=EFE2E35ABB96F0B4F7DA3AE05DCDF81D"
            alt=""
            style={{ textAlign: "center", width: "100%" }}
          />
        </div>
      </div>
      <div className="border" style={{ marginBottom: "4rem" }}>
        <div className="d-flex justify-content-center" style={{ height: "350px", width: "100%" }}>
          <img
            src="/assets/banners/Bharat1.png"
            height={"100%"}
            width={"400px"}
            alt=""
            style={{ textAlign: "center" }}
          />
        </div>
      </div>
      <div className=" mt-5">
        <div>
          <h3 className="text-center " style={{ lineHeight: "1.8", wordSpacing: "7px", letterSpacing: "1px", fontFamily: "Open Sans ,sans-serif", }}>Jiva offers Industrial Automation Solutions to a <br /> wide range of Industry segments and application areas</h3>
        </div>
        <div className="row row-cols-lg-5 g-4 g-lg-3 p-4">

          {data.map(({ title, icon, animate }) => {
            return (
              <>
                <div className="col" data-aos="zoom-in" data-aos-duration="500">
                  <div
                    className="p-3 border"
                    style={{
                      height: "200px",
                      width: "260px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={icon}
                      className="industryIcon"
                      alt=""
                      style={{ height: "40%", width: "40%" }}
                    />
                    <div style={{ marginTop: "30px", width: "100%", textAlign: "center" }}>
                      <p style={{ fontWeight: "400", fontSize: "19px", width: "100%" }}>{title} </p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>


      {/* <!-- Testimonial Start --> */}

      <div className="py-5 wow fadeInUp">
        <div className="py-5">
          <div className="text-center">
            <h6 className="text-secondary text-uppercase">Testimonial</h6>
            <h1 className="mb-0">Our Clients Say!</h1>
          </div>
          {/*  */}
         
          <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner" style={{height:"33vh"}}>
              <div class="carousel-item active">
                <div className="testimonial-item p-4 my-5">
                  <div className="d-flex align-items-end mb-4">
                    <img
                      className="img-fluid flex-shrink-0"
                      src="/assets/Narayana/Customers/Fortune 500/HONDA.png"
                      style={{ width: "80px", height: "80px" }}
                    />
                    <div className="ms-4">
                      <h5 className="mb-1">Client Name</h5>
                      <p className="m-0">Profession</p>
                    </div>
                  </div>
                  <p className="mb-0">
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                    amet diam et eos. Clita erat ipsum et lorem et sit.
                  </p>
                </div>
              </div>
              <div class="carousel-item">
                <div className="testimonial-item p-4 my-5">
                  <div className="d-flex align-items-end mb-4">
                    <img
                      className="img-fluid flex-shrink-0"
                      src="/assets/Narayana/Customers/Fortune 500/VOLVO.png"
                      style={{ width: "80px", height: "80px" }}
                    />
                    <div className="ms-4">
                      <h5 className="mb-1">Client Name</h5>
                      <p className="m-0">Profession</p>
                    </div>
                  </div>
                  <p className="mb-0">
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                    amet diam et eos. Clita erat ipsum et lorem et sit.
                  </p>
                </div>
              </div>
              <div class="carousel-item ">
                <div className="testimonial-item p-4 my-5">
                  <div className="d-flex align-items-end mb-4">
                    <img
                      className="img-fluid flex-shrink-0"
                      src="/assets/Narayana/Customers/Fortune 500/TOYOTA.png"
                      style={{ width: "80px", height: "80px" }}
                    />
                    <div className="ms-4">
                      <h5 className="mb-1">Client Name</h5>
                      <p className="m-0">Profession</p>
                    </div>
                  </div>
                  <p className="mb-0">
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                    amet diam et eos. Clita erat ipsum et lorem et sit.
                  </p>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
          
          {/*  */}
        </div>
      </div>
      {/* <!-- Testimonial End --> */}
    </>
  );
}

export default Industary;
