import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import { MdMail } from "react-icons/md";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div
      className=" bg-dark text-light footer pt-5 wow fadeIn"
      data-wow-delay="0.1s"
      style={{ marginTop: "6rem" }}
    >
      <div className="px-3 py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Address</h4>
            <p className="mb-2" style={{lineHeight:"0"}}>
            <ImLocation2 size={18} color="red" />  No.360, 43rd Main
              Road
            </p>
            <p className="ms-3" >Ideal Homes Township, Rajarajeshwarinagar</p>
            <p className="ms-3" style={{lineHeight:"0"}}>Bengaluru 560098</p>
            <p className="mb-2">
             <BsFillTelephoneFill size={18} color="red" /> +91 99729 63729
            </p>
            <p className="mb-2">
            <MdMail  size={18} color="red"/>  info@jivatech.com
            </p>
            <div className="d-flex p-2 ">
              <a className="m-2" href="">
                <FaTwitter size={25} />
              </a>
              <a className="m-2" href="">
                <FaFacebook size={25} />
              </a>
              <a className="m-2" href="">
                <FaYoutube size={25} />{" "}
              </a>
              <a className="m-2" href="">
                <FaLinkedin size={25} />{" "}
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Services</h4>
            <Link to="/engineering-services" className="btn btn-link" href="">
              Engineering Services
            </Link>
            <Link to="/system-intergration" className="btn btn-link" href="">
              System Integration
            </Link>
            <Link to="industrial-cranes" className="btn btn-link" href="">
              Industrial Cranes
            </Link>
            <Link to="/power-tools" className="btn btn-link" href="">
              Copco Power Tools
            </Link>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Navigation</h4>
            <Link to="/company" className="btn btn-link" href="">
              About Us
            </Link>
            <Link to="/contact" className="btn btn-link" href="">
              Contact Us
            </Link>
            <Link className="btn btn-link" href="">
              Our Services
            </Link>
            <Link className="btn btn-link" href="">
              Terms & Condition
            </Link>
            <Link className="btn btn-link" href="">
              Support
            </Link>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Contact Us</h4>

            <div
              className="position-relative mx-auto"
              style={{ maxWidth: "400px" }}
            >
              <input
                className="form-control border-0 w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Your email"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <a className="border-bottom" href="#">
                Jiva Automations
              </a>
              , All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              Designed By{" "}
              <a className="border-bottom" href="https://bloomitsolutions.com">
                Bloom IT Solutions
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
