import ReactWhatsapp from "react-whatsapp";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { FaWhatsapp } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { PiWhatsappLogoFill } from "react-icons/pi";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { useState,useEffect } from "react";
import { BsTelephone } from "react-icons/bs";

const CallToAction = () => {
  const handlePhoneClick = () => {
    window.location.href = `tel:${8028605322}`;
  };


  const [scrollPosition, setScrollPosition] = useState(0);

const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
};

useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

console.log(scrollPosition)

  return (
    
    <>
      <div className="whatsapp-container" >
        <ReactWhatsapp
          number="+91 8028605258"
          message="next"
          className="whatsapp"
        >
          <FaWhatsapp   style={{marginLeft:"6px", fontSize:"45px", marginBottom:"22px", color:"green"}} />
       {/* <img src="/assets/whatsapp.png" height={"50px"} /> */}
        </ReactWhatsapp>
      </div>

      <div className="phone-container">
        <button className="phonecall" onClick={handlePhoneClick}>
          {/* <MdOutlinePermPhoneMsg /> */}
          {/* <MDBIcon fas icon="phone" /> */}
          <BsTelephone  size={35} />
        </button>
      </div>

     { scrollPosition >= 70 ? <div className="scroll-container">
        <button className="scroll_icon" 
        onClick={()=>window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        })}>
          <IoIosArrowDropupCircle size={50} />
        </button>
      </div>:""}
    </>
  );
};

export default CallToAction;
