import React, { useEffect, useState } from "react";
import { BsRocketTakeoffFill } from "react-icons/bs";
import { TbBrandTripadvisor } from "react-icons/tb";
import Partners from "./Partners";

const Company = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div style={{ height:isMobile ?"40vh": "60vh",backgroundImage:"url('/assets/banners/about_mob.png')" }} className="page-header">
        {isMobile ? (
          <img
            // src="/assets/banners/about_mob.png"
            alt="Mobile Image"
            className="img-fluid"
          />
        ) : (
          <img
            // src="/assets/banners/about.png"
            alt="Laptop Image"
            className="img-fluid"
          />
        )}
        <div data-aos="fade-left" data-aos-duration="1000" className="h-100 w-100 d-flex justify-content-start align-items-center px-5">
            <h1 className="text-white" style={{fontSize:"75px"}}>
              About<br/>
             <span style={{color:"rgb(246, 139, 65)"}}>JIVA</span>
            </h1>
        </div>
      </div>

      <div className="overflow-hidden px-lg-0 " style={{ marginTop: "30px" }}>
        <div className=" about px-2 px-lg-0" style={{marginBottom:"20px"}}>
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-6 ps-lg-0 wow fadeInLeft"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  data-aos="zoom-in-up"
                  className="position-absolute img-fluid w-100 h-100"
                  src="/assets/Narayana/MaterialHand1.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 about-text ">
              <h6
                className="fs-2 text-uppercase mb-3"
                style={{ color: "#f68b41" }}
              >
                About Us
              </h6>
              <p
                className="mb-5"
                style={{
                  lineHeight: "1.7",
                  fontSize: "19px",
                  textAlign: "justify",
                }}
              >
                Starting in 2006, our tagline “Adding Life to Automation”
                defines our beliefs in creating Smart Customized Solutions for
                Smart Factories. Founders of Jiva Innovative Automations Pvt
                Ltd., have been associated with reputed companies like M/s.
                Siemens, M/s. Kirloskar with over 80-man years of combined
                experience in similar industries exhibited a pioneering spirit
                and passion for Quality, Total Commitment to Customers on
                Products and Solutions, a highly skilled and motivated Team,
                this underpins everything that we do and defines the company’s
                goals to date.
              </p>
              <div className="row g-4 mb-5">
                <div
                  className="col-sm-6 col-lg-3 wow fadeIn"
                  data-wow-delay="0.5s"
                >
                  <img src="/assets/Bharat.png" height="60px" alt="" />

                  <p className="m-0">
                    PAN India operations 30 locations presence 100+ employees
                  </p>
                </div>
                <div
                  className="col-sm-6 col-lg-3 wow fadeIn"
                  data-wow-delay="0.7s"
                >
                  {/* <i className="fa fa-shipping-fast fa-3x text-primary mb-3"></i> */}
                  <img src="/public/assets/turnover.png" height="60px" alt="" />
                  <p className="m-0">Turnover in excess of INR 150 Million</p>
                </div>
                <div className="col-sm-6 col-lg-3 wow fadeIn">
                  <img
                    src="/assets/Fortune-Companines.png"
                    height="60px"
                    alt=""
                  />

                  <p className="m-0">
                    FORTUNE 500 Customer Over 100 active customers
                  </p>
                </div>
                <div
                  className="col-sm-6 col-lg-3 wow fadeIn"
                  data-wow-delay="0.7s"
                >
                  {/* <i className="fa fa-shipping-fast fa-3x text-primary mb-3"></i> */}
                  <img src="/assets/servicesIcon.png" height="60px" alt="" />
                  <p className="m-0">
                    4 Business Verticals Engineering Services ,System
                    Integration,Turnkey Solutions,Product Sales & Solutions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section_our_solution">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="our_solution_category">
              <div className="solution_cards_box">
                <div className="solution_card">
                  <div className="hover_color_bubble"></div>
                  <div className="d-flex justify-content-start flex-row">
                    <div className="solu_title d-flex">
                      <TbBrandTripadvisor size={40} />
                      <h2 style={{ marginLeft: "7px", color: "#f68b41" }}>
                        Our Vision
                      </h2>
                    </div>
                  </div>
                  <div className="solu_description">
                    <p style={{ fontSize: "22px" }}>
                      To improve our client's competitive position by providing
                      Cost Effective, Innovative Industrial Control & Automation
                      Solutions meeting the highest standards of Quality &
                      Client expectation.
                    </p>
                  </div>
                </div>
                <div className="solution_card">
                  <div className="hover_color_bubble"></div>
                  <div className="d-flex justify-content-start flex-row">
                    <div className="so_top_icon">
                      <BsRocketTakeoffFill size={40} />
                    </div>
                    <div className="solu_title">
                      <h2 style={{ marginLeft: "7px", color: "#f68b41" }}>
                        Our Mission
                      </h2>
                    </div>
                  </div>
                  <div></div>
                  <div className="solu_description">
                    <p style={{ fontSize: "22px" }}>Pursue excellence daliy</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="partners">
        <Partners />
      </div>
    </>
  );
};

export default Company;
