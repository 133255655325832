import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";

function Photos() {
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleImageClick = (src) => {
    setImageSrc(src);
    setShowModal(true);
  };
  const images = [
    "/assets/Photos/img1.jpg",
    "/assets/Photos/img2.jpg",
    "/assets/Photos/img3.JPG",
    "/assets/Photos/img4.JPG",
    "/assets/Photos/img5.JPG",
    "/assets/Photos/img6.jpg",
    "/assets/Photos/img7.jpg",
    "/assets/Photos/img8.jpeg",
    "/assets/Photos/img9.jpeg",
    "/assets/Photos/img10.jpeg",
    "/assets/Photos/img11.jpg",
    "/assets/Photos/img12.JPG",
    "/assets/Photos/img13.JPG",
    "/assets/Photos/img14.JPG",
    "/assets/Photos/img15.jpg",
    "/assets/Photos/img16.jpg",
    "/assets/Photos/img17.jpg",
    "/assets/Photos/img18.jpg",
    "/assets/Photos/img19.jpg",
    "/assets/Photos/img20.jpg",
    "/assets/Photos/img21.jpg",
  ];

  return (
    <div>
      <div className="container py-5">
        <div className="row row-cols-1 row-cols-lg-4  ">
          {images.map((src, index) => {
            return (
              <>
                <div
                  className="col mb-4"
                  data-aos="zoom-out"
                  data-aos-duration="1000"
                >
                  <div
                    key={index}
                    className="p-3 border "
                    style={{
                      height: "300px",
                      width: "310px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={src}
                      style={{ height: "100%", width: "100%" }}
                      onClick={() => handleImageClick(src)}
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      {/* <Modal
        centered
        style={{ width: "100%" }}
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <img src={imageSrc} height={"100%"} width={"100%"} alt="" />
      </Modal> */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body>
          <img src={imageSrc} height={"100%"} width={"100%"} alt="" />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Photos;
