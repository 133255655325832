import { TiTick } from "react-icons/ti";
import { Link } from "react-router-dom";

const EngineeringServices = () => {
  return (
    <>
      {/* Header */}
      <div style={{ height: "100%" }}  className="position-relative">
        <img
          className="img-fluid"
          src="/assets/banners/Engineering_Services.png"
          alt=""
        />
          <div data-aos="zoom-in-down" data-aos-duration="1000"
         className=" h-100 w-100 d-flex justify-content-center align-items-center px-5 position-absolute top-0 ">
            {/* <h1 className="text-white" style={{fontSize:"75px"}}>
             Engineering Services
              <span style={{color:"rgb(246, 139, 65)"}}>JIVA</span> 
            </h1> */}
        </div>
      </div>

      {/* Engineering services */}
      <div className="overflow-hidden px-lg-0 " style={{ marginTop: "50px", }}>
        <div className="about px-2  px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
             
              className="col-lg-6 ps-lg-0"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="assets/Photos/Cat manipulator (4).jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text"
             
            >
              <h6
                className="fs-2 text-uppercase mb-3"
                style={{ color: "#f68b41" }}
              >
                Engineering Services
              </h6>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">
                  Jiva Group company supporting Siemens Automation as an
                  Authorized Service Partner for PAN India operation.
                </p>
              </div>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">Robotic simulations and Engineering</p>
              </div>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">
                  Electrical Engineering AutoCAD / EPLAN FEA Analysis
                </p>
              </div>
              <div className="d-flex">
                <TiTick size={38} />
                <p className="mb-3">
                  Critical High End & niche Machine Shopfloors and Automated
                  Process Industry Maintenance Services with critical Spares
                  Support (Electrical, Electronic & Mechanical)
                </p>
              </div>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3"> Prototyping and 3D Printing</p>
              </div>
              <div className="d-flex">
                <TiTick size={23} />
                <p className="mb-3">
                  Offsite & Onshire Automation and Instrumentation related
                  Coding, P&ID's & Electrical Wiring
                </p>
              </div>

              <Link
                to=""
                className="btn py-3 px-5"
                style={{ backgroundColor: "#f68b41" }}
              >
                Explore More
              </Link>
            </div>
          </div>
        </div>
        <div className="py-3 " style={{marginTop:"40px"}}>
          <div className="row row-cols-1 row-cols-lg-4  ">
            <div className="col">
              <div
               
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img1.jpg"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
            <div className="col">
              <div
               
               
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img2.jpg"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
            <div className="col">
              <div
               
               
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img3.jpeg"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
            <div className="col">
              <div
               
               
                className="px-2 border "
                style={{ height: "400px", width: "100%", objectFit: "cover" }}
              >
                <img
                  src="/assets/System Integration/img4.JPG"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EngineeringServices;
