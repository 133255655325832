import Leadership from "./Leadership";
import React, { useEffect, useState } from "react";

const WhyJiva = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const CardData = [
    {
      imageUrl: "/assets/why-jiva/rating.png",
      text: "Experience of over 100-man years in Industrial Automation and related area.",
      animate: "fade-right",
    },
    {
      imageUrl: "/assets/why-jiva/training.png",
      text: "Ethical business practices.",
      animate: "fade-left",
    },
    {
      imageUrl: "/assets/why-jiva/enterprise.png",
      text: "Fortune 500 Companies as customers and repeat business.",
      animate: "fade-left",
    },
    {
      imageUrl: "/assets/why-jiva/technology.png",
      text: "Technology Collaborations with best in Industry Experts.",
      animate: "fade-up-right",
    },
    {
      imageUrl: "/assets/why-jiva/inHouse.png",
      text: "In-house Engineering strength for customized solutions.",
      animate: "fade-up-left",
    },
    {
      imageUrl: "/assets/why-jiva/idea-exchange.png",
      text: "Our depth of knowledge and wide range of capabilities for varied industry verticals.",
      animate: "flip-down",
    },
    {
      imageUrl: "/assets/why-jiva/rating.png",
      text: "Commitment to customer, focus on total customer satisfaction.",
      animate: "zoom-in",
    },
    {
      imageUrl: "/assets/why-jiva/quality-control.png",
      text: "Highest Quality at Honest Value.",
      animate: "zoom-in-up",
    },
    {
      imageUrl: "/assets/why-jiva/completed-task.png",
      text: "Projects completed on time and within budget.",
      animate: "flip-left",
    },
    {
      imageUrl: "/assets/why-jiva/licence-icon.png",
      text: "Licensed Tools & tackles.",
      animate: "flip-left",
    },
  ];
  return (
    <>
      <div style={{ height:isMobile ?"40vh": "60vh",backgroundImage:"url('/assets/banners/about_mob.png')" }} className="page-header">
        {/* {isMobile ? (
          <img
            // src="/assets/banners/about_mob.png"
            alt="Mobile Image"
            className="img-fluid"
          />
        ) : (
          <img
            // src="/assets/banners/about.png"
            alt="Laptop Image"
            className="img-fluid"
          />
        )} */}
        <div data-aos="fade-right" data-aos-duration="500" className="h-100 w-100 d-flex justify-content-start align-items-center px-5">
            <h1 className="text-white px-3" style={{fontSize:"75px"}}>
              why<br/>
             <span style={{color:"rgb(246, 139, 65)"}}>JIVA</span>
            </h1>
        </div>
      </div>

      <div className="overflow-hidden px-lg-0 " style={{ marginTop: "90px" }}>
        <h6
          className=" fs-1 text-uppercase mb-3"
          style={{
            textAlign: "center",
            marginTop: "",
            marginBottom: "20px",
            color: "#f68b41",
          }}
        >
          Why Jiva
        </h6>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2 g-lg-3">
      {CardData.map((item, index) => (
        <div className="col" key={index}>
          <div className="p-3 border" style={{ height: "147.6px" }}>
            <div
              style={{
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                width={"100%"}
                height={"100%"}
                src={item.imageUrl}
                alt=""
              />
            </div>
            <div>
              <p
                style={{
                  fontSize: "17px",
                  marginTop: "20px",
                  fontWeight: "500",
                }}
              >
                {item.text}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
      </div>
      <Leadership />
    </>
  );
};

export default WhyJiva;
