import React from "react";
import { TiTick } from "react-icons/ti";

function AtlasCopco() {
  return (
    <>
      {/* Header */}
      <div style={{ height: "100%" }}>
        <img
          className="img-fluid"
          src="/assets/System Integration/atlas_copco.png"
          alt=""
        />
      </div>
      {/* Header End */}

      {/* Atlas Copco Power tools */}
      <div className="px-2" style={{ marginTop: "50px" }}>
        <div className="px-2">
          <div className="row g-5">
            <div  data-aos="zoom-in-right"
              className="col-lg-6 wow fadeInUp"
             
            >
              <h6 className="text-primary fs-2 text-uppercase mb-3">
                Atlas Copco Power Tools
              </h6>
              <div>
                <p style={{ lineHeight: "2" }}>
                  We are South India Master Channel Partner for ATLAS COPCO
                  Pneumatic Tools & Air Motors. We offer all tooling Products &
                  Solutions of ATLAS COPCO & provided tailormade solutions on
                  our Engineering & Design strength for special applications
                  requiring Fixtures, Mounting: Overhead & other Ergonomic tool
                  holding solutions, Automated Assembly stations, Robotic
                  stations...etc based on the requirements of
                  specific applications.{" "}
                </p>
              </div>
            </div>
            <div
              className="col-lg-6"
           
            >
              <div className="row g-4 align-items-center" data-aos="zoom-out">
                <div className="col-sm-6">
                  <div
                    className="p-2 border "
                    style={{
                      height: "400px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <img
                      src="/assets/System Integration/img3.jpeg"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    className="p-3 border "
                    style={{
                      height: "400px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <img
                      src="/assets/System Integration/img3.jpeg"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="row row-cols-1 row-cols-md-2 g-2">
                <div className="col">
                  <div
                    className="card text-dark mb-3"
                    style={{ maxWidth: "100%" }}
                   
                  >
                    <div className="card-body">
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Industrial Tools and Solutions</p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Aerospace industrial solutions</p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Smart Connected Assembly</p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Building heavy trucks and buses</p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">
                          Sockets and bits for industrial power tools
                        </p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Fabrication and Maintenance</p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Air Motors</p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Bolting Solutions</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="card text-dark mb-3"
                    style={{ maxWidth: "100%" }}
                  
                  >
                    <div className="card-body">
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">
                          Assembly Tools: Battery & Electric
                        </p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Controller and Systems</p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">
                          Quality assurance and manual tightening
                        </p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Fixtured Application Solutions</p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Material Removal Tools</p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Air Line Infrastructure</p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">
                          Fabrication and Maintenance PRO range
                        </p>
                      </div>
                      <div className="d-flex">
                        <TiTick size={23} />
                        <p className="mb-3">Industrial Torque Wrenches</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3">
              <div className="row row-cols-1 row-cols-lg-4  ">
                <div className="col">
                  <div
                    data-aos="zoom-in-up"
                    className="p-3 border "
                    style={{
                      height: "400px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <img
                      src="/assets/System Integration/power_tool_1.png"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div
                   data-aos="zoom-in-up"
                    className="p-3 border "
                    style={{
                      height: "400px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <img
                      src="/assets/System Integration/power_tool_2.png"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div
                    data-aos="zoom-in-up"
                    className="p-3 border "
                    style={{
                      height: "400px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <img
                      src="/assets/System Integration/power_tool_3.png"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div
                    data-aos="zoom-in-up"
                    className="p-3 border "
                    style={{
                      height: "400px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <img
                      src="/assets/System Integration/power_tool_4.png"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Atlas Copco Power tools */}
    </>
  );
}

export default AtlasCopco;
