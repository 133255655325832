import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Partners = () => {
  const carouselSetting = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 9,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2, // Adjusted to scroll 2 slides at a time in mobile view
          autoplay: true, // Enable autoplay in mobile view
          autoplaySpeed: 2000, // Set autoplay speed to 2 seconds
        },
      },
    ],
  };

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 9,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const firstLine = [
    "/assets/Narayana/Customers/Fortune 500/TOYOTA.png",
    "/assets/Narayana/Customers/Fortune 500/VOLVO.png",
    "/assets/Narayana/Customers/Fortune 500/HONDA.png",
    "/assets/Narayana/Customers/Fortune 500/Siemens-Logo.png",
    "/assets/Narayana/Customers/Fortune 500/Bosch-logo.svg.png",
    "/assets/Narayana/Customers/Fortune 500/CAPERTILLER.png",
    "/assets/Narayana/Customers/Fortune 500/SCHNEIDER.png",
    "/assets/Narayana/Customers/Fortune 500/SAINT_GOBAIN.png",
    "/assets/Narayana/Customers/Fortune 500/AMNS.png",
  ];
  const secondLine = [
    "/assets/Narayana/Customers/TVS.png",
    "/assets/Narayana/Customers/HAL.png",
    "/assets/Narayana/Customers/Toyota_Tsusho.png",
    "/assets/Narayana/Customers/MAHINDRA AEROSPACE.webp",
    "/assets/Narayana/Customers/FEDERAL MOGUL.png",
    "/assets/Narayana/Customers/SUNGROW.png",
    "/assets/Narayana/Customers/CLIMAVENETA.png",
    "/assets/Narayana/Customers/EBERSPACHER.png",
    "/assets/Narayana/Customers/TECHNIP FMC.png",
    "/assets/Narayana/Customers/DIAGIO.png",

    // 
    "/assets/Narayana/Customers/AeroCision.png",
    "/assets/Narayana/Customers/Amara_Raja_Batteries_Limited_3.png",
    "/assets/Narayana/Customers/ATHER.png",
    "/assets/Narayana/Customers/EXCIDE.png",
    "/assets/Narayana/Customers/INIDAN PISTON.png",
    "/assets/Narayana/Customers/SCHMERSAL.png",
    "/assets/Narayana/Customers/TDPS.png",
    "/assets/Narayana/Customers/United_Spirits.png"
  ];
  const thirdLine = [
    "/assets/Narayana/Customers/FOSROC.png",
    "/assets/Narayana/Customers/BEML.png",
    "/assets/Narayana/Customers/L&T_KOMATSU.png",
    "/assets/Narayana/Customers/ITC.png",
    "/assets/Narayana/Customers/AJAX.png",
    "/assets/Narayana/Customers/TENAX.png",
    "/assets/Narayana/Customers/Faiveley_Transport-Logo.wine.png",
    "/assets/Narayana/Customers/Reckitt-Benckiser.png",
    "/assets/Narayana/Customers/VOSSLAH.png",
    "/assets/Narayana/Customers/kk wind.png",
    "/assets/Narayana/Customers/DANFOSS.png",
    // 
    "/assets/Narayana/Customers/AKZONOBEL.png",
    "/assets/Narayana/Customers/ARMOR.png",
    "/assets/Narayana/Customers/brbnmpl-logo.png",
    "/assets/Narayana/Customers/HK WENTWORTH.png",
    "/assets/Narayana/Customers/NPCIL.png",
    "/assets/Narayana/Customers/SUNDRAM_CLAYTON.png",
    "/assets/Narayana/Customers/TOYOTA_BOSHOKU.png",
    "/assets/Narayana/Customers/L___T_Logo.png"
  ];
  return (
    <>
      <div className="container-fluid p-5">
        <div className="py-4">
          <h2 className="text-center">OUR CUSTOMERS</h2>
        </div>
        <div>
          <Slider {...carouselSetting}>
            {firstLine.map((item, index) => (
              <div
                key={index}
                className="logo-slide d-flex align-items-center justify-content-center mt-3"
              >
                <img
                  src={item}
                  alt={item}
                  className="rounded"
                  loading="lazy"
                  style={{
                    maxWidth: "200px",
                    maxHeight: "200px",
                    width: "100%",
                    height: "100%",
                    aspectRatio: 3 / 2,
                    objectFit: "contain",
                    mixBlendMode: "color-burn",
                    paddingLeft: "60px",
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div>
          <Slider {...carouselSettings}>
            {secondLine.map((item, index) => (
              <div
                key={index}
                className="logo-slide d-flex align-items-center justify-content-center mt-3"
              >
                <img
                  src={item}
                  alt={item}
                  className="rounded"
                  loading="lazy"
                  style={{
                    maxWidth: "200px",
                    maxHeight: "200px",
                    width: "100%",
                    height: "100%",
                    aspectRatio: 3 / 2,
                    objectFit: "contain",
                    mixBlendMode: "color-burn",
                    paddingLeft: "60px",
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div>
          <Slider {...carouselSettings}>
            {thirdLine.map((item, index) => (
              <div
                key={index}
                className="logo-slide d-flex align-items-center justify-content-center mt-3"
              >
                <img
                  src={item}
                  alt={item}
                  className="rounded"
                  loading="lazy"
                  style={{
                    maxWidth: "200px",
                    maxHeight: "200px",
                    width: "100%",
                    height: "100%",
                    aspectRatio: 3 / 2,
                    objectFit: "contain",
                    mixBlendMode: "color-burn",
                    paddingLeft: "60px",
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Partners;
